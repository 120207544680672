// src/components/Loader.js
import React from 'react';
import './style.css'; // Asegúrate de que la ruta sea correcta

const LoadVideo = ({ message = "Estamos cargando los videos" }) => {
  return (
  <div className="loadVideo">
    <div className="spinnerLoad"></div>
    <p className="loadd-message">{message}</p>

  </div>
 );
};

export default LoadVideo;
