// src/components/Loader.js
import React from 'react';
import './style.css'; // Asegúrate de que la ruta sea correcta

const LoaderProcess = () => (
  <div className="loader">
    <div className="spinner"></div>
    <p className="loader-message">Estamos procesando tu video, recuerda que al terminar de procesar debes dar click en el boton "subir video"</p>
  </div>
);

export default LoaderProcess;
