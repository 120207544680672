// src/components/Loader.js
import React from 'react';
import './style.css'; // Asegúrate de que la ruta sea correcta

const Loader = ({ message = "Estamos cargando los videos" }) => {
  return (
  <div className="loader">
    <div className="spinner"></div>
    <p className="loader-message">{message}</p>

  </div>
 );
};

export default Loader;
