import React, { useState } from 'react';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import LoadVideo from '../LoadVideo/index'; // Actualiza el nombre del import
import LoaderProcess from '../LoaderProcess/index'; // Loader for processing video
import './style.css';

function UploadPage() {
  const [newVideo, setNewVideo] = useState({
    name: '',
    file: null,
    location: '',
    agresor: '',
    agredido: '',
    delitos: '',
    minororadult: null,
  });
  const [fileName, setFileName] = useState('');
  const [loading, setLoading] = useState(false); // For uploading
  const [processing, setProcessing] = useState(false); // For processing
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewVideo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    setFileName(file ? file.name : '');

    if (file && (file.type === 'video/mp4' || file.type === 'video/quicktime')) {
      setProcessing(true);
      try {
        const base64File = await convertVideoToBase64(file);
        setNewVideo((prev) => ({ ...prev, file: base64File }));
      } catch (error) {
        console.error('Error converting file to base64:', error);
        setError('Error al convertir el archivo. Intenta de nuevo.');
      } finally {
        setProcessing(false);
      }
    } else {
      setError('El formato de video no es compatible.');
    }
  };

  const convertVideoToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
    });
  };

  const handleSubmit = async () => {
    if (newVideo.file) {
      setLoading(true); // Show uploading loader
      setError('');

      const formData = {
        name: newVideo.name || '',
        place: newVideo.location || '',
        urlvideo: newVideo.file,
        crime: newVideo.delitos || '',
        aggressor: newVideo.agresor || '',
        attacked: newVideo.agredido || '',
        minororadult: newVideo.minororadult !== null ? newVideo.minororadult : null,
      };

      try {
        const response = await fetch('https://be-8epm.onrender.com/api/saveVideo', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });

        if (response.ok) {
          Swal.fire({
            title: '¡Éxito!',
            text: 'El video se subió correctamente.',
            icon: 'success',
            confirmButtonText: 'Aceptar',
          }).then(() => {
            setNewVideo({
              name: '',
              file: null,
              location: '',
              agresor: '',
              agredido: '',
              delitos: '',
              minororadult: null,
            });
            setFileName('');
            navigate('/home');
          });
        } else {
          Swal.fire({
            title: 'Error',
            text: 'No se pudo subir el video. Inténtalo de nuevo.',
            icon: 'error',
            confirmButtonText: 'Aceptar',
          });
        }
      } catch (error) {
        setError('Error al subir el video');
        console.error('Error al subir el video:', error);

        Swal.fire({
          title: 'Error',
          text: 'Hubo un problema al intentar subir el video. Por favor, intenta de nuevo.',
          icon: 'error',
          confirmButtonText: 'Aceptar',
        });
      } finally {
        setLoading(false); // Hide uploading loader
      }
    } else {
      setError('Estamos procesando el video, espera unos segundos y presiona "subir video".');
    }
  };

  return (
    <div className="upload-page">
      {loading && <LoadVideo message="Estamos subiendo tu video" />} {/* Actualiza el componente Loader */}
      {processing && <LoaderProcess />} {/* Muestra el LoaderProcess para el procesamiento del video */}

      <header className='form-header'>
        <Icon icon="lets-icons:back" className='form-header-icon' onClick={() => navigate('/home')} />
        <div className='circle-wrapper'>
          <span className='form-circle' style={{background: "var(--color_003)"}}></span>
          <span className='form-circle' style={{background: "var(--color_004)"}}></span>
          <span className='form-circle' style={{background: "var(--color_005)"}}></span>
        </div>
      </header>

      <div className='title-wrapper-'>
        <p className='form-title'>Nuevo <span>Video</span></p>
        <p className='form-desc'>Completa la información seleccionada para poder compartir tu video</p>
      </div>

      <form className='form-wrapper'>
        <div className='input-wrapper'>
          <Icon icon="rivet-icons:pencil-solid" className='form-input-icon'/>
          <input
            className='form-input'
            name="name"
            placeholder='Título'
            value={newVideo.name}
            onChange={handleChange}
          />
        </div>

        <div className='input-wrapper'>
          <Icon icon="fluent:location-28-filled" className='form-input-icon'/>
          <input
            className='form-input'
            name="location"
            placeholder='Lugar'
            value={newVideo.location}
            onChange={handleChange}
          />
        </div>

        <div className='input-wrapper'>
          <Icon icon="mingcute:user-2-fill" className='form-input-icon'/>
          <input
            className='form-input'
            name="agresor"
            placeholder='Agresor'
            value={newVideo.agresor}
            onChange={handleChange}
          />
        </div>

        <div className='input-wrapper'>
          <Icon icon="mingcute:user-2-fill" className='form-input-icon'/>
          <input
            className='form-input'
            name="agredido"
            placeholder='Agredido'
            value={newVideo.agredido}
            onChange={handleChange}
          />
        </div>

        <div className='input-wrapper'>
          <Icon icon="ph:hand-fist-fill" className='form-input-icon'/>
          <input
            className='form-input'
            name="delitos"
            placeholder='Delito'
            value={newVideo.delitos}
            onChange={handleChange}
          />
        </div>

        <div className='input-wrapper'>
          <Icon icon="mingcute:user-fill" className='form-input-icon'/>
          <select
            className='form-input'
            name="minororadult"
            value={newVideo.minororadult || ""}
            onChange={(e) => setNewVideo(prev => ({ ...prev, minororadult: Number(e.target.value) }))}
          >
            <option value="">Seleccionar edad</option>
            <option value="1">Menor de Edad</option>
            <option value="2">Adulto</option>
          </select>
          <Icon icon="gridicons:dropdown" className='form-dropdown-icon'/>
        </div>

        <div className='input-wrapper'>
          <label className='form-file-label'>
            <input
              type="file"
              accept="video/*"
              onChange={handleFileChange}
              hidden
            />
            <span className='form-file-button'>Seleccionar Video</span>
          </label>
          <span className='file-name'>{fileName}</span>
        </div>

        <button
          type="button"
          className='form-upload'
          onClick={handleSubmit}
          disabled={loading || processing} // Disable button when loading or processing
        >
          <Icon icon="ic:baseline-upload" className='form-upload-icon'/>
          <span className='form-upload-text'>Subir video</span>
        </button>

        {error && <div className="error-message">{error}</div>}
      </form>
    </div>
  );
}

export default UploadPage;
