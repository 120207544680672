import React, { useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import CommentSection from '../commentSection';
import { faFacebookF, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
import './style.css'; 

const convertQuickTimeToMP4 = (base64QuickTime) => {
  if (base64QuickTime.startsWith('data:video/quicktime;base64,')) {
    return base64QuickTime.replace('data:video/quicktime;base64,', 'data:video/mp4;base64,');
  }
  return base64QuickTime;
};

function VideoList({ videos }) {
  const videoRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        const videoElement = entry.target;

        if (entry.isIntersecting) {
          // Pausar todos los videos menos el que está en foco
          videoRefs.current.forEach((video) => {
            if (video && video !== videoElement && !video.paused) {
              video.pause();
            }
          });

          
          videoElement.play().catch(error => {
            console.error('Error playing video:', error);
          });
        } else {
       
          videoElement.pause();
        }
      });
    }, { threshold: 0.5 }); 

   
    videoRefs.current.forEach((video) => {
      if (video) {
        observer.observe(video);
      }
    });

    return () => {
  
      videoRefs.current.forEach((video) => {
        if (video) {
          observer.unobserve(video);
        }
      });
    };
  }, [videos]);
  const handleShareFacebook = () => {
    const facebookUrl = 'https://www.facebook.com/';
    window.open(facebookUrl, '_blank');
  };

  const handleShareInstagram = () => {
    const instagramUrl = 'https://www.instagram.com/';
    window.open(instagramUrl, '_blank');
  };

  const handleShareTikTok = () => {
    const tiktokUrl = 'https://www.tiktok.com/';
    window.open(tiktokUrl, '_blank');
  };

  const sortedVideos = [...videos].reverse();

  return (
    <div className="video-list">
      {sortedVideos.map((video, index) => {
        const videoUrl = convertQuickTimeToMP4(video.urlvideo);

        return (
          <div key={video.id} className="video-item">
            <div className="video-name">
              {video.name}
              <div className="icon-container">
                <FontAwesomeIcon icon={faFacebookF} className="icon" onClick={handleShareFacebook} />
                <FontAwesomeIcon icon={faInstagram} className="icon" onClick={handleShareInstagram} />
                <FontAwesomeIcon icon={faTiktok} className="icon" onClick={handleShareTikTok} />
                <a 
                  href={videoUrl} 
                  download={`${video.name}.mp4`}
                >
                  <FontAwesomeIcon icon={faDownload} className="icon" />
                </a>
              </div>
            </div>
            <video
              ref={el => videoRefs.current[index] = el}
              src={videoUrl}
              controls
              className="video"
              playsInline
            />
            {video.urlvideo ? (
              <div className="video-controls">
                {/* Controles personalizados si los necesitas */}
              </div>
            ) : (
              <div className="video-error">El video no está disponible.</div>
            )}
            <CommentSection videoId={video.id} />
          </div>
        );
      })}
    </div>
  );
}

export default VideoList;

