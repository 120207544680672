import React, { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import bxSearchAlt2 from '@iconify/icons-bx/bx-search-alt-2';
import icOutlineAddCircleOutline from '@iconify/icons-ic/outline-add-circle-outline';
import icOutlineInfo from '@iconify/icons-ic/outline-info';
import { useNavigate } from 'react-router-dom';
import VideoList from '../../components/videoList';
import { getData } from '../../config/https';
import Loader from '../../components/Loader'; // Importa el componente Loader
import './style.css';

// Función de debounce para controlar la frecuencia del manejo de eventos
const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
};

function HomePage() {
  const [videos, setVideos] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false); // Estado para la carga
  const [hasMore, setHasMore] = useState(true); // Estado para determinar si hay más videos para cargar
  const [offset, setOffset] = useState(0); // Inicialmente 3
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const navigate = useNavigate();

  const loadVideos = async () => {
    if (loading) return; // No hacer nada si ya se está cargando
  
    setLoading(true);
  
    try {
      // Determina el límite para la carga de videos
      const currentLimit = isFirstLoad ? 5 : 4; // Usa 5 para la primera carga, 4 para las siguientes
      const storedVideos = await getData(`https://be-8epm.onrender.com/api/videos?limit=${currentLimit}&offset=${offset}`);
  
      // Si no hay más videos, establece hasMore en false
      if (storedVideos.length < currentLimit) {
        setHasMore(false);
      }
  
      setVideos(prevVideos => {
        // Filtra videos para eliminar duplicados
        const uniqueVideos = [
          ...prevVideos,
          ...storedVideos.filter(video => !prevVideos.some(v => v.id === video.id))
        ];
        return uniqueVideos;
      });
  
      // Actualiza el offset para la siguiente carga
      setOffset(prevOffset => prevOffset + currentLimit); // Incrementa según el número de videos cargados
  
      // Marca como no es la primera carga después de la primera carga
      if (isFirstLoad) {
        setIsFirstLoad(false);
      }
    } catch (error) {
      console.error('Error loading videos:', error);
    } finally {
      setLoading(false);
    }
  };
 

  // Carga inicial de videos
  useEffect(() => {
    loadVideos();
  }, []);

  // Manejador de desplazamiento para cargar más videos con debounce
  useEffect(() => {
    const handleScroll = debounce(() => {
      const scrollTop = window.innerHeight + document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.offsetHeight;

      // Cargar más videos si el usuario está cerca del final de la página
      if (scrollTop + 100 >= scrollHeight && !loading && hasMore) {
        loadVideos();
      }
    }, 200); // Ajusta el retraso según sea necesario

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [loading, hasMore]);

  const handleSearchChange = (e) => setSearchTerm(e.target.value);

  const handleShareClick = () => navigate('/upload');
  const handleAboutClick = () => navigate('/about');

  const filteredVideos = videos.filter(video =>
    video.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  );

  return (
    <div className='Home-page'>
      <div className="home">
        <div className="header">
          <div className="circle-wrapper">
            <span className='home-circle' style={{ background: "var(--color_003)" }}></span>
            <span className='home-circle' style={{ background: "var(--color_004)" }}></span>
            <span className='home-circle' style={{ background: "var(--color_005)" }}></span>
          </div>
          <div className="title-wrapper">
            <p className='home-title'>Denuncia<br />con <span>Nosotros</span></p>
            <p className='home-desc'>Buscamos transformar la indignación en acción, y el silencio impuesto en un eco que resuene en todo el mundo</p>
          </div>
          <div className="button-wrapper">
            <button className="home-button" style={{ background: "var(--color_003)" }} onClick={handleShareClick}>
              <Icon icon={icOutlineAddCircleOutline} className='home-button-icon' />
              <span className='home-button-text'>Publicar</span>
            </button>
            <button className="home-button" style={{ background: "var(--color_001)" }} onClick={handleAboutClick}>
              <Icon icon={icOutlineInfo} className='home-button-icon' />
              <span className='home-button-text'>Acerca de</span>
            </button>
          </div>
          <div className="home-search">
            <input
              className="home-search-input"
              placeholder="Buscar"
              onChange={handleSearchChange}
            />
            <Icon icon={bxSearchAlt2} className="home-search-icon" />
          </div>
        </div>

        <div className="subtitle-wrapper">
          <p className="home-subtitle">Videos</p>
          <hr className="home-line" />
        </div>

        {loading && <Loader />}
        <VideoList videos={filteredVideos} />
      </div>
    </div>
  );
}

export default HomePage;
