import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "../../pages/homePage";
import NotFoundPage from "../../pages/notFoundPage";
import WelcomePage from "../../pages/welcomePage";
import UploadPage from "../../components/uploadPage";
import About from "../../pages/AboutPage";

const ConfigRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<WelcomePage />} /> {/* Página de bienvenida en la ruta raíz */}
      <Route path="/home" element={<HomePage />} /> {/* Página principal en /home */}
      <Route path="/about" element={<About />} /> {/* Página principal en /home */}
      <Route path="/upload" element={<UploadPage />} /> {/* Página para el modal */}
      <Route path="*" element={<NotFoundPage />} /> {/* Página 404 para rutas no encontradas */}
    </Routes>
  );
};

export default ConfigRoute;
