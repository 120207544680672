
import React from 'react';
import ConfigRoute from './config/routes';

function App() {
  return <ConfigRoute/>

}

export default App;
