import React, { useState, useEffect } from 'react';
import { getData, postData } from '../../config/https';
import './styles.css';

function CommentSection({ videoId }) {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [loading, setLoading] = useState(true);

  // Fetch and filter comments for the specific video
  useEffect(() => {
    const loadComments = async () => {
      if (videoId) { // Only fetch comments if videoId is provided
        setLoading(true);
        try {
          const fetchedComments = await getData('https://be-8epm.onrender.com/api/comments');
          
          // Filter comments for the specific video
          const filteredComments = fetchedComments.filter(comment => comment.video_id === videoId);
          
          setComments(filteredComments);
        } catch (error) {
          console.error('Error fetching comments:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    loadComments();
  }, [videoId]);

  const handleCommentChange = (e) => {
    setNewComment(e.target.value);
  };

  const handleCommentSubmit = async () => {
    if (newComment.trim()) {
      setLoading(true);
      try {
        // Post the new comment
        await postData('https://be-8epm.onrender.com/api/saveComment', {
          comment_text: newComment,
          video_id: videoId
        });

        // Clear comments list
        setComments([]);

        // Fetch comments again after posting
        const fetchedComments = await getData('https://be-8epm.onrender.com/api/comments');
        
        // Filter comments for the specific video
        const filteredComments = fetchedComments.filter(comment => comment.video_id === videoId);
        
        setComments(filteredComments);
        setNewComment('');
      } catch (error) {
        console.error('Error posting comment:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="comment-section">
      <h3>Comentarios</h3>
      {loading ? (
        <p>Cargando comentarios...</p>
      ) : (
        <>
          {comments.length > 0 ? (
            <table className="comments-table">
              <thead>
                <tr>
                </tr>
              </thead>
              <tbody>
                {comments.map((comment) => (
                  <tr key={comment.id}>
                    <td> • {comment.comment_text}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No hay comentarios para este video.</p>
          )}
          <div className="add-comment">
            <textarea
              value={newComment}
              onChange={handleCommentChange}
              placeholder="Añadir un comentario..."
            />
            <button onClick={handleCommentSubmit}>Enviar</button>
          </div>
        </>
      )}
    </div>
  );
}

export default CommentSection;
