import image from '../../image/image_002.svg';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import './style.css';
import icOutlineAddCircleOutline from '@iconify/icons-ic/outline-add-circle-outline';

function About() {
  const navigate = useNavigate();
const handleShareClick = () => navigate('/upload'); 
  return (
    <div className='About-page'>
      
    <div className="about">

      {/* - - - </> [DIV] </> - - - */}
      <div className='about-header'>

        {/* - - - </> [ICON Back] </> - - - */}
        <Icon icon="lets-icons:back" className='form-header-icon' onClick={() => navigate('/home')} />

        {/* - - - </> [DIV] </> - - - */}
        <div className='circle-wrapper'>

          {/* - - - </> [SPAN] </> - - - */}
          <span className='about-circle' style={{background: "var(--color_003)"}}></span>

          {/* - - - </> [SPAN] </> - - - */}
          <span className='about-circle' style={{background: "var(--color_004)"}}></span>

          {/* - - - </> [SPAN] </> - - - */}
          <span className='about-circle' style={{background: "var(--color_005)"}}></span>

        </div>

      </div>

      {/* - - - </> [IMAGE] </> - - - */}
      <img src={image} className='about-image'></img>

      {/* - - - </> [DIV] </> - - - */}
      <div className='title-wrapper'>

        {/* - - - </> [TEXT] </> - - - */}
        <p className='about-title'>Acerca de<br></br><span>Efecto Dominó</span></p>

        {/* - - - </> [TEXT] </> - - - */}
        <p className='about-desc'>Aspiramos a crear un espacio de libertad donde las verdades ocultas por la censura puedan salir a la luz. Esperamos que, con el tiempo, esta iniciativa contribuya a presionar por cambios significativos en la protección de los derechos humanos en Venezuela, y que inspire a otros a unirse a la lucha por la justicia y la libertad.
        .
        </p>

        {/* - - - </> [TEXT] </> - - - */}
        <p className='about-desc'>En Proyecto Dominó, creemos que cada denuncia es un paso hacia un futuro más justo. Con Efecto Dominó, buscamos transformar la indignación en acción, y el silencio impuesto en un eco que resuene en todo el mundo. Únete a nosotros en esta lucha por la verdad, la justicia y los derechos humanos.</p>

      </div>

      {/* - - - </> [BUTTON Compartir] </> - - - */}
      <button className="home-button" style={{ background: "var(--color_003)" }} onClick={handleShareClick}>
            <Icon icon={icOutlineAddCircleOutline} className='home-button-icon' />
            <span className='home-button-text'>Publicar</span>
          </button>
    </div>
  </div>
  );
}

export default About;
