import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import image from '../../image/image_001.svg';
import './style.css';

function WelcomePage() {
  const navigate = useNavigate();

  const handleContinue = () => {
    navigate('/home'); // Redirige al usuario a la página principal
  };

  return (
    <div class="page-welcome" >
    <div className="loading">
      
    {/* - - - </> [DIV] </> - - - */}
    <div className='circle-wrapper'>

      {/* - - - </> [SPAN] </> - - - */}
      <span className='loading-circle' style={{background: "var(--color_003)"}}></span>

      {/* - - - </> [SPAN] </> - - - */}
      <span className='loading-circle' style={{background: "var(--color_004)"}}></span>

      {/* - - - </> [SPAN] </> - - - */}
      <span className='loading-circle' style={{background: "var(--color_005)"}}></span>
      
    </div>

    {/* - - - </> [IMAGE] </> - - - */}
    <img src={image} className='loading-image'></img>

    <button onClick={handleContinue} className="continue-button" style={{ textAlign: 'center', width: '100%' }}>
          <FontAwesomeIcon icon={faArrowRight} className="icon" />
          Continuar
        </button>

  </div>

  </div>

  );
}

export default WelcomePage;
